import React, { Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TravelDesigners from "../components/TravelDesigners/TravelDesigners"

const TravelDesignersMain = () => {
  return (
    <Fragment>
      <TravelDesigners />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO
      pageTitle="Meet our Travel Designers"
      description="Meet our travel designers"
    />
    <TravelDesignersMain />
  </Layout>
)
